import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Col, Container, Row} from "react-bootstrap";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import styles from "components/layout/postGrid.module.scss";
import SimplePostListCard from "components/cards/SimplePostListCard";


const PostGrid = (props) => {
    const {posts, size, smallMargin, hideText, squareMargin, showProduct, showInlineProduct, hideTextAndProduct, verticalMargin, horizontalMargin} = props;
    const onPostClick = props.onPostClick || function () {};
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const xl = props.xl || 3;

    const halfHorizontalMargin = horizontalMargin ? horizontalMargin / 2.0 : null;

    return (
        <Container>
            <div className={utilStyles.flexRow} >
                <Row
                    className={classNames(utilStyles.fullFlex, styles.row, smallMargin && styles.smallMarginRow, squareMargin && styles.squareRow)}
                >
                    {
                        posts.map((post, idx) => {
                            if (post.media === undefined && post.media_list && post.media_list.length > 0) {
                                post.media = post.media_list[0];
                            }
                            return (
                                <Col key={post.id}
                                     xs={size ? size : 6}
                                     lg={size ? size : (isLarge ? 4 : 6)}
                                     xl={size ? size : xl}
                                     className={classNames(styles.col, smallMargin && styles.smallMarginCol, squareMargin && styles.squareCol)}
                                     style={{marginTop: verticalMargin, paddingLeft: halfHorizontalMargin, paddingRight: halfHorizontalMargin}}
                                >
                                    <SimplePostListCard post={post} hideText={hideText} onPostClick={(post) => onPostClick(post, idx)}
                                                        showInlineProduct={showInlineProduct} hideTextAndProduct={hideTextAndProduct} showProduct={showProduct}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </Container>
    );
};

export default PostGrid;
