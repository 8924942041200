import MainBanner from "components/sections/MainBanner";
import BeautyToolShortcutIcon from "images/icons/beauty_tool_shortcut.svg";
import RankingIcon from "images/icons/ranking.svg";
import TimeSaleIcon from "images/icons/time_sale.svg";
import HairShortcutIcon from "images/icons/hair_shortcut.svg";
import MakeupShortcutIcon from "images/icons/makeup_shortcut.svg";
import SkincareShortcutIcon from "images/icons/skincare_shortcut.svg";
import BrandsIcon from "images/icons/brands.svg";
import EventShortcutIcon from "images/icons/event_shortcut.svg";
import SellerShopShortcutIcon from "images/icons/seller_shop_shortcut.svg";
import ArrowRightIcon from "images/icons/arrow_right_757575.svg";
import styles from "pages/index.module.scss";
import utilStyles from "styles/utils.module.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";
import ProductListCard from "components/cards/ProductListCard";
import {useContext, useEffect as reactUseEffect, useState} from "react";
import {Axios, defaultCacheOption} from "api";
import {productScrapContextRef} from "context/ProductScrapContext";
import {captureException, captureMessage} from "@sentry/nextjs";
import Link from "next/link";
import {
    brandRoute,
    eventsRoute,
    getStoreCategoryRoute,
    professionalPriceRoute,
    reservationProductsRoute, rewardsRoute,
    sellerShopRoute,
    SITE_URL,
    specialOffersRoute,
    storeRoute
} from "common/const";
import {postScrapContextRef} from "context/PostScrapContext";
import {isApp, shuffle} from "common/utils";
import Head from "next/head";
import {useRouter} from "next/router";
import UserContext from "context/AuthContext";
import {HASH_PRODUCT_LIST} from "pages/store/products";

import NewBadgeIcon from "images/icons/new_badge.svg";
import PushContext, {PushContextProvider} from "context/PushContext";
import {KEY_PUSH_MARKETING_AGREEMENT} from "pages/mypage/my-info";
import Layout from "components/layout/layout";
import TopBar from "components/layout/TopBar/TopBar";
import MainPageSection from "components/layout/MainPage/MainPageSection";
import ModalContext from "context/ModalContext";
import MainCouponModal, {mainCouponModalKey} from "components/modal/MainCouponModal";
import {checkIfOpenAllowed} from "common/modalHelper";

import HemeTicketIcon from "images/static/heme_ticket_icon.png";
import EventIcon from "images/static/event.png";
import RewardIcon from "images/static/present_box.png";
import {MainPageSectionList} from "components/sections/mainpage/MainPageSectionList";

const CATEGORY_TOP_PRODUCT = "category_top_products";
const ROW_CHUNK_SIZE = 20;

const Shortcut = ({link, icon, name, shortcutIdx, isNew,}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MainPageShortcutClick', {name, shortcutIdx, isNew});
    };

    return (
        <div className={styles.shortcutWrapper} onClick={onClick}>
            <Link href={link}>
                <a className={styles.shortcut}>
                    <div className={styles.icon}>
                        {icon}
                        {
                            !!isNew &&
                            <div className={styles.newBadge}>
                                <NewBadgeIcon/>
                            </div>
                        }
                    </div>
                    <span className={styles.shortcutText}>{name}</span>
                </a>
            </Link>
        </div>
    )
}


const fetchMainPageInfo = async (cookie) => {
    let mainPageInfo = {};

    try {
        const res = await Axios.get(
            `/v1/main-page-info`,
            {
                headers: {Cookie: cookie || ''},
                cache: defaultCacheOption,
            }
        );
        if (res.status < 400) {
            mainPageInfo = res.data;
        } else {
            captureMessage(JSON.stringify(res.data));
        }
    } catch (e) {
        captureException(e);
    }
    return mainPageInfo;
}


const SpecialOfferSection = ({sectionIdx, title, link, invisible, showPromotionDaysLeft}) => {
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const numItems = isLarge ? 8 : 4;

    reactUseEffect(() => {
        getSpecialOffers();
    }, []);

    const getSpecialOffers = async () => {
        try {
            setIsLoading(true);
            setProducts([]);
            const res = await Axios.get(
                'v1/store/special-offer-products/', {
                    params: {order: 'sell_count'},
                    cache: defaultCacheOption,
                });
            if (res.status < 400) {
                shuffle(res.data.products);
                setProducts(res.data.products);
                productScrapContextRef.current.updateByProductIdsAndScrappedProductIds(
                    res.data.products.map(e => e.id), res.data['scrapped_pids'],
                );
            } else {
                setError(res.data.display_message || '페이지를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch (e) {
            setError('페이지를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.');
        } finally {
            setIsLoading(false);
        }
    };

    const onSectionProductClick = (itemIdx, product) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MainPageSectionProductClick', {
            section: title,
            sectionIdx: sectionIdx,
            itemIdx,
            productId: product.id,
        });
    };

    return (
        products.length > 0 &&
        <div className={classNames(invisible ? utilStyles.hide : undefined)}>
            <div className={utilStyles.mobileBorder}/>
            <div className={styles.section}>
                <Link href={link || ''}>
                    <a className={classNames(styles.sectionTitleWrapper, link ? utilStyles.cursorPointer : utilStyles.cursorUnset)}>
                        <span className={styles.sectionTitle}>{title}</span>
                        {
                            link &&
                            <ArrowRightIcon width="20" height="20" viewBox="0 0 12 12"/>
                        }
                    </a>
                </Link>
                <div className={classNames(styles.specialOfferRow, utilStyles.hideScrollbar)}>
                    {
                        products.length > 0
                            ?
                            products.slice(0, numItems).map((product, idx) => {
                                return (
                                    <div key={product.id} className={styles.specialOfferCol}>
                                        <ProductListCard
                                            showPromotionDaysLeft={showPromotionDaysLeft}
                                            product={product}
                                            productNameMaxLine={2}
                                            numOptionsDisplay={isMobile ? 'bottom' : 'right'}
                                            onClick={() => onSectionProductClick(idx, product)}
                                        />
                                    </div>
                                )
                            })
                            :
                            <div key={"empty"} className={styles.col}>
                                <div/>
                            </div>
                    }
                </div>
            </div>
        </div>
    )

}


const SHORTCUT_NAME_RANKING = '랭킹';
const SHORTCUT_NAME_HAIR = '헤어';
const SHORTCUT_NAME_MAKEUP = '메이크업';
const SHORTCUT_NAME_SKINCARE = '스킨케어';
const SHORTCUT_NAME_TOOL = '뷰티툴';
const SHORTCUT_NAME_SPECIAL_PRICE = '헤메코특가';
const SHORTCUT_NAME_EVENT = '이벤트';
const SHORTCUT_NAME_FEED = '리뷰피드';
const SHORTCUT_NAME_BRAND = '브랜드';
const SHORTCUT_NAME_SELLER_SHOP = '편집샵';
const SHORTCUT_NAME_RESERVATION = '헤메티켓';
const SHORTCUT_NAME_REWARD = '리워드';

const SHORTCUT_NAME_PROFESSIONAL_PRICE = 'PRO특가';


const LOCAL_STORAGE_KEY_PUSH_MODAL_CLOSED = 'push_modal_closed';


const Index = (props) => {
    const userContext = useContext(UserContext);
    const fetchUser = userContext.fetchUser;
    const user = userContext.user;

    const pushContext = useContext(PushContext);

    const [isPushModalOpen, setIsPushModalOpen] = useState(false);
    const [mainPageInfo, setMainPageInfo] = useState(props.mainPageInfo || {});
    console.log('mainPageInfo', mainPageInfo);

    const router = useRouter();

    const isSSR = props.isSsr;

    const [isCsrLoading, setIsCsrLoading] = useState(true);

    const useEffect = props.useEffect || reactUseEffect;

    useEffect(async () => {
        if (!isSSR) {
            setIsCsrLoading(true);
            const mainPageInfo = await fetchMainPageInfo();
            setIsCsrLoading(false);
            setMainPageInfo(mainPageInfo);
        }
    }, []);

    useEffect(() => {
        if (!user) return;
        const isModalCloseDone = window.localStorage.getItem(LOCAL_STORAGE_KEY_PUSH_MODAL_CLOSED);
        if (!isModalCloseDone && isApp()) {
            pushContext.hasPushPermission((enabled) => {
                if (user) {
                    if (!user[KEY_PUSH_MARKETING_AGREEMENT] || enabled) {
                        setIsPushModalOpen(true);
                    }
                }
            });
        }
    }, [user]);

    useEffect(() => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ViewMainPage');
        fetchUser();
    }, []);


    useEffect(() => {
        if (!productScrapContextRef.current) return;
        if (!postScrapContextRef.current) return;
        if (!mainPageInfo.curations) return;
        for (const curation of mainPageInfo.curations) {
            if (curation.category === 'product' || curation.category === 'daily') {
                productScrapContextRef.current.addProductIds(curation.scrapped_pids);
            } else if (curation.category === 'post') {
                postScrapContextRef.current.addPostIds(curation.scrapped_pids);
            }
        }
    }, [mainPageInfo, productScrapContextRef.current, postScrapContextRef]);

    // useEffect(async () => {
    //     if (!productScrapContextRef.current) return;
    //
    //     const section_product_ids = mainPageInfo.main_page_sections ?
    //         mainPageInfo.main_page_sections.map(section => section.products.map(e => e.id)).flat()
    //         :
    //         [];
    //
    //     try {
    //         const res = await Axios.post('/v1/store/get-scrapped-pids', {product_ids: section_product_ids});
    //         if (res.status < 400) {
    //             productScrapContextRef.current.addProductIds(res.data.scrapped_product_ids);
    //         } else {
    //             captureMessage(JSON.stringify(res.data));
    //         }
    //     } catch (e) {
    //         captureException(e);
    //     }
    // }, [productScrapContextRef.current]);

    // useEffect(async () => {
    //     if (!postScrapContextRef.current) return;
    //
    //     const section_post_ids = mainPageInfo.main_page_sections ?
    //         mainPageInfo.main_page_sections.map(section => section.posts.map(e => e.id)).flat()
    //         :
    //         [];
    //
    //     try {
    //         const res = await Axios.post('/v1/palette/get-scrapped-pids', {post_ids: section_post_ids});
    //         if (res.status < 400) {
    //             postScrapContextRef.current.addPostIds(res.data.scrapped_post_ids);
    //         } else {
    //             captureMessage(JSON.stringify(res.data));
    //         }
    //     } catch (e) {
    //         captureException(e);
    //     }
    // }, [postScrapContextRef.current]);

    const newShortCuts = mainPageInfo.new_shortcuts || [];

    const onBannerClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MainPageShortcutBannerClick', {isLoggedIn: mainPageInfo.is_logged_in});
    };


    const receivedCouponMoldIds = mainPageInfo.received_coupon_mold_ids || [];

    const {openModal, closeModal, registerAppModalCloseCallback} = useContext(ModalContext);

    // useEffect(() => {
    //     const mainPageModals = mainPageInfo?.main_page_modals;
    //     if (mainPageModals) {
    //         for (const modal of mainPageModals) {
    //             if (modal.coupon_molds.filter(
    //                 e => e.is_duplication_allowed || !receivedCouponMoldIds.includes(e.id)
    //             ).length === 0) continue;
    //
    //             if (!checkIfOpenAllowed(mainCouponModalKey(modal.id))) {
    //                 continue;
    //             }
    //
    //             if (modal.is_app_only && !isApp()) {
    //                 continue;
    //             }
    //
    //             openModal(() =>
    //                 <MainCouponModal
    //                     modalInfo={modal} close={closeModal}
    //                     receivedCouponMoldIds={receivedCouponMoldIds}
    //                 />
    //             );
    //             break;
    //         }
    //     }
    // }, [mainPageInfo]);

    const isPro = !!user && !!user.pro_grade;
    const isBetaTester = !!user && user.is_beta_tester;

    return (
        <>
            <Head>
                <link rel="canonical" href={`${SITE_URL}${router.asPath}`}/>
            </Head>
            {
                <MainBanner banners={mainPageInfo.banners} isLoading={isCsrLoading}/>
            }
            <div className={utilStyles.pageContainer}>
                <div className={styles.shortcutSection}>
                    <div className={styles.shortcutContainer}>
                        <div className={styles.shortcutRow}>
                            <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_RANKING)}
                                      link={`${storeRoute}?order=sell_count#${HASH_PRODUCT_LIST}`} icon={<RankingIcon/>}
                                      name={SHORTCUT_NAME_RANKING} shortcutIdx={0}/>
                            <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_MAKEUP)}
                                      link={getStoreCategoryRoute('메이크업')} icon={<MakeupShortcutIcon/>}
                                      name={SHORTCUT_NAME_MAKEUP} shortcutIdx={1}/>
                            <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_HAIR)}
                                      link={getStoreCategoryRoute('헤어')} icon={<HairShortcutIcon/>}
                                      name={SHORTCUT_NAME_HAIR} shortcutIdx={2}/>
                            <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_SKINCARE)}
                                      link={getStoreCategoryRoute('스킨케어')} icon={<SkincareShortcutIcon/>}
                                      name={SHORTCUT_NAME_SKINCARE} shortcutIdx={3}/>
                            <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_TOOL)}
                                      link={getStoreCategoryRoute('메이크업_툴/디바이스')} icon={<BeautyToolShortcutIcon/>}
                                      name={SHORTCUT_NAME_TOOL} shortcutIdx={4}/>
                        </div>
                        <div className={styles.shortcutRow}>
                            {
                                isPro &&
                                <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_PROFESSIONAL_PRICE)}
                                          link={professionalPriceRoute}
                                          icon={<img src={'https://d1cnx04b8cgzcv.cloudfront.net/pricetag_color.png'} style={{width: 38, height: 38, marginBottom: 2}} />}
                                          name={SHORTCUT_NAME_PROFESSIONAL_PRICE} shortcutIdx={5}/>
                            }
                            <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_SPECIAL_PRICE)} link={specialOffersRoute} icon={<TimeSaleIcon/>} name={SHORTCUT_NAME_SPECIAL_PRICE} shortcutIdx={5}/>
                            <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_EVENT)} link={eventsRoute} icon={
                                <img src={EventIcon.src} width={40} height={40}/>
                            } name={SHORTCUT_NAME_EVENT} shortcutIdx={6}/>
                            <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_BRAND)} link={brandRoute} icon={<BrandsIcon/>} name={SHORTCUT_NAME_BRAND} shortcutIdx={7}/>
                            {
                                !isPro ?
                                <>
                                    <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_REWARD)} link={rewardsRoute} icon={<img src={RewardIcon.src} width={48} height={48}/>} name={SHORTCUT_NAME_REWARD} shortcutIdx={8}/>
                                    <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_RESERVATION)}
                                              link={reservationProductsRoute}
                                              icon={<img src={HemeTicketIcon.src} width={54} height={54}/>}
                                              name={SHORTCUT_NAME_RESERVATION} shortcutIdx={9}/>
                                </> :
                                    <Shortcut isNew={newShortCuts.includes(SHORTCUT_NAME_REWARD)} link={rewardsRoute} icon={<img src={RewardIcon.src} width={48} height={48}/>} name={SHORTCUT_NAME_REWARD} shortcutIdx={9} />
                            }
                        </div>
                        {/*<Link href={couponsRoute}>*/}
                        {/*    <a className={styles.shortcutBannerImage} onClick={onBannerClick}>*/}
                        {/*        <Image unoptimized src={*/}
                        {/*            mainPageInfo.is_logged_in*/}
                        {/*                ? "https://d1cnx04b8cgzcv.cloudfront.net/media/banner_images_mobile/checkout_success_member_coupon_banner.png"*/}
                        {/*                : "https://d1cnx04b8cgzcv.cloudfront.net/media/banner_images_mobile/checkout_success_nonmember_coupon_banner.png"*/}
                        {/*        } layout="fill"/>*/}
                        {/*    </a>*/}
                        {/*</Link>*/}
                        {/*<Shortcut link={getStoreCategoryRoute('MAKEUP')} icon={<MakeupShortcutIcon/>} name={"메이크업"}/>*/}
                        {/*<Shortcut link={getStoreCategoryRoute('HAIR')} icon={<HairShortcutIcon/>} name={"헤어"}/>*/}

                        {/*<Shortcut link={specialOffersRoute} icon={<TimeSaleIcon/>} name={"헤메코특가"}/>*/}

                        {/*<Shortcut link={brandRoute} icon={<SellerShopShortcutIcon/>} name={"편집샵"}/>*/}
                    </div>
                </div>
                {
                    mainPageInfo.mid_banners &&
                    mainPageInfo.mid_banners.length > 0 &&
                    <div className={styles.midBannerContainer}>
                        <div className={styles.midBannerWrapper}>
                            <MainBanner banners={mainPageInfo.mid_banners} isLoading={false} hidePaginator small />
                        </div>
                    </div>
                }
                <div className={utilStyles.mobileBorder}/>
                <MainPageSectionList />
            </div>
        </>
    )
}

export const _Index = Index;

const WrappedIndex = (props) => {
    const user = useContext(UserContext).user;

    return (
        <Layout topBar={
            <TopBar
                search
                isPro={!!user && user.pro_grade}
                notification
                // order
                pouch/>
        }>
            <Index {...props} />
        </Layout>
    );
}

export async function getServerSideProps(context) {
    const mainPageInfo = await fetchMainPageInfo(context.req.headers.cookie);

    return {
        props: {
            mainPageInfo: mainPageInfo,
            isSsr: true,
        },
    }
}

export default WrappedIndex;
WrappedIndex.restoreScroll = true;
